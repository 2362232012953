module.exports = {
  title: 'Rafid Al Haque', // Required
  author: 'Rafid Al Haque', // Required
  description: '',
  primaryColor: '#3498db', // Required
  showHeaderImage: true,
  showShareButtons: true,
  postsPerPage: 5, // Required
  social: {
    website: 'https://rafidalhaque.github.io',
    github: 'https://github.com/rafidalhaque',
    twitter: 'https://twitter.com/rafidalhaque',
    Telegram: 'https://t.me/rafidalhaque',
  },
  pathPrefix: '/contact',
  siteUrl: 'https://rafidalhaque.github.io/contact/',
};
